<template>
  <v-row>
    <v-col cols="12" class="py-0 px-0">
      <v-data-table
        :headers="headers"
        :items="data"
        :expanded.sync="expanded"
        hide-default-footer
        item-key="id"
        :items-per-page="sizes[size]"
        :show-expand="expandedHeaders.length > 0"
        :loading="loading"
        loading-text="Loading data from API...please wait"
        class="synapsa-table firewallgroup-table"
      >
        <template v-slot:item.name="{ item }">
          <span
            v-if="item.source_id === 3 && item.parser_type_id === 1"
            class="form-link"
            @click="open(item)"
          >
            {{ item.name ? item.name : "-" }}
          </span>
          <span v-else>
            {{ item.name ? item.name : "-" }}
          </span>
        </template>
        <template v-slot:item.syslog_type="{ item }">
          <span v-if="item.syslog_type === 1"> CEF </span>
          <span v-else> - </span>
        </template>
        <template v-slot:item.tags="{ item }">
          <span v-if="item.tags.length === 0">-</span>
          <div v-else>
            <v-chip
              v-for="(tag, index) in item.tags"
              :key="index"
              x-small
              color="neutral-4"
              class="mr-1 text-white"
            >
              {{ tag }}
            </v-chip>
          </div>
        </template>
        <template v-slot:item.lists="{ item }">
          <span v-if="item.lists.length === 0">-</span>
          <div v-else>
            <v-chip
              v-for="(item, index) in item.lists"
              :key="index"
              x-small
              color="neutral-4"
              class="mr-1 text-white"
            >
              {{ item }}
            </v-chip>
          </div>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td
            :colspan="headers.length"
            class="expand-block"
            :class="data.indexOf(item) === data.length - 1 ? 'last' : ''"
          >
            <v-simple-table>
              <template v-slot:default>
                <tbody class="expanded">
                  <tr
                    v-for="(expandItem, key) in expandedHeaders"
                    :key="expandItem.value"
                    class="expand-tr"
                    :class="
                      data.indexOf(item) === data.length - 1 &&
                      key === expandedHeaders.length - 1
                        ? 'last'
                        : ''
                    "
                  >
                    <!--NAME-->
                    <td
                      v-if="expandItem.value === 'name'"
                      width="150"
                      class="pl-3"
                    >
                      Name:
                    </td>
                    <td v-if="expandItem.value === 'name'">
                      {{ item.name ? item.name : "-" }}
                    </td>
                    <!--END NAME-->

                    <!--SYSLOG TYPE-->
                    <td
                      v-if="expandItem.value === 'syslog_type'"
                      width="150"
                      class="pl-3"
                    >
                      Syslog Type:
                    </td>
                    <td v-if="expandItem.value === 'syslog_type'">
                      {{ item.syslog_type === 1 ? "CEF" : "-" }}
                    </td>
                    <!--END SYSLOG TYPE-->

                    <!--DESCRIPTION-->
                    <td
                      v-if="expandItem.value === 'description'"
                      width="150"
                      class="pl-3"
                    >
                      Description:
                    </td>
                    <td v-if="expandItem.value === 'description'">
                      {{ item.description ? item.description : "-" }}
                    </td>
                    <!--END DESCRIPTION-->

                    <!--TAGS-->
                    <td
                      v-if="expandItem.value === 'threats'"
                      width="150"
                      class="pl-3"
                    >
                      Threats:
                    </td>
                    <td v-if="expandItem.value === 'threats'">
                      <span v-if="item.tags.length === 0">-</span>
                      <div v-else>
                        <v-chip
                          v-for="(tag, index) in item.tags"
                          :key="index"
                          x-small
                          color="neutral-4"
                          class="mr-1 text-white"
                        >
                          {{ tag }}
                        </v-chip>
                      </div>
                    </td>
                    <!--END TAGS-->

                    <!--LISTS-->
                    <td
                      v-if="expandItem.value === 'lists'"
                      width="150"
                      class="pl-3"
                    >
                      Variables:
                    </td>
                    <td v-if="expandItem.value === 'lists'">
                      <span v-if="item.lists.length === 0">-</span>
                      <div v-else>
                        <v-chip
                          v-for="(item, index) in item.lists"
                          :key="index"
                          x-small
                          color="neutral-4"
                          class="mr-1 text-white"
                        >
                          {{ item }}
                        </v-chip>
                      </div>
                    </td>
                    <!--END LISTS-->
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>
      </v-data-table>
    </v-col>
    <v-col v-if="data.length > 0" cols="6" class="py-0 px-0 synapsa-pagination">
      <v-btn-toggle v-model="size" tile group class="results px-1">
        <v-btn x-small class="result" rounded> 5 </v-btn>

        <v-btn x-small class="result" rounded> 10 </v-btn>

        <v-btn x-small class="result" rounded> 25 </v-btn>

        <v-btn x-small class="result" rounded> 50 </v-btn>

        <v-btn x-small class="result" rounded> 100 </v-btn>

        <v-btn x-small class="result" rounded> 200 </v-btn>
      </v-btn-toggle>
    </v-col>
    <v-col
      v-if="data.length > 0"
      cols="6"
      class="text-right py-0 px-0 synapsa-pagination"
      :class="$vuetify.theme.dark ? 'theme--dark' : 'theme--light'"
    >
      <v-pagination
        v-model="page"
        :length="pageTotalCount"
        :total-visible="7"
        circle
        class="d-inline-block pagination px-1 py-1"
        :class="page > 99 ? 'over100' : 'less100'"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    headers: {
      type: Array,
      default: function () {
        return [];
      },
    },
    expandedHeaders: {
      type: Array,
      default: function () {
        return [];
      },
    },
    data: {
      type: Array,
      default: function () {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    tablePage: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    tablePageTotalCount: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    tableSize: {
      type: Number,
      default: function () {
        return 1;
      },
    },
  },
  data() {
    return {
      expanded: [],

      page: 1,
      pageTotalCount: 1,
      size: 1,

      sizes: [5, 10, 25, 50, 100, 200],
    };
  },
  computed: {
    keywordAddForm: {
      get() {
        return this.$store.state.settings.keywordAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "keywordAddForm",
          value: val,
        });
      },
    },
    idAddForm: {
      get() {
        return this.$store.state.settings.idAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "idAddForm",
          value: val,
        });
      },
    },
  },
  watch: {
    tablePage: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.page = newValue;
      },
    },
    tablePageTotalCount: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.pageTotalCount = newValue;
      },
    },
    tableSize: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) this.size = this.sizes.indexOf(newValue);
      },
    },
    page: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue && newValue !== this.tablePage) {
          this.$emit("change", { page: newValue, size: this.sizes[this.size] });
        }
      },
    },
    size: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (
          newValue !== undefined &&
          newValue !== this.sizes.indexOf(this.tableSize)
        ) {
          this.$emit("change", { page: this.page, size: this.sizes[newValue] });
        }
      },
    },
  },
  methods: {
    open(item) {
      this.idAddForm = item.id;
      this.keywordAddForm = "parser";
    },
  },
};
</script>

<style lang="scss" scoped></style>
